/** @jsxImportSource theme-ui */
import { memo, useState } from 'react'
import SlideDown from 'react-slidedown'
import { useNavDropdown } from 'src/context/UIContext'
import { Box, Container, Flex, Text } from 'theme-ui'
import { useLockBodyScroll } from '~/hooks/use-lock-body-scroll'
import { HeaderHeight, getHeaderLinkId } from '../Header/types'
import DropdownItem from './Dropdown-Item'

interface DropdownLink {
  text: string
  image: {
    url: string
    height: number
    width: number
  }
  url: string
}

interface Dropdown {
  title: string
  linksCollection: {
    items: DropdownLink[]
  }
}

type Props = {
  isOpen: boolean
  dropdown?: Dropdown
  customDropdown?: Dropdown
  onClickLink: (url: string) => void
  mobileHidden?: boolean
}

function Dropdown({
  isOpen,
  dropdown,
  customDropdown,
  onClickLink,
  mobileHidden,
}: Props) {
  const setDropdownId = useNavDropdown()[1]
  const close = () => setDropdownId(null)
  //useLockBodyScroll({ lock: isOpen })

  const [isShopCBD, setIsShopCBD] = useState(true)

  const renderLinks = () => {
    if (!dropdown?.linksCollection?.items?.length) return null

    return (
      <Box
        sx={{
          p: isOpen ? 2 : 0,
          height: isOpen ? '100%' : '0px',
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'row'],
            width: '100%',
            maxHeight: [null, `calc(100vh - 120px)`],
          }}
        >
          <Flex
            sx={{
              flexDirection: 'column',
              width: '100%',
              paddingX: [0, 4],
            }}
          >
            <Flex
              sx={{
                gap: 4,
                px: 2,
                justifyContent: 'space-between',
                marginBottom: 4,
                display: ['flex'],
              }}
            >
              <Text
                sx={{
                  fontWeight: 'bold',
                  fontSize: [2, 6],
                  color: isShopCBD ? '#19b4eb' : 'text',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsShopCBD(true)
                }}
              >
                {dropdown?.title}
              </Text>

              <Text
                sx={{
                  fontWeight: 'bold',
                  fontSize: [2, 6],
                  color: !isShopCBD ? '#19b4eb' : 'text',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setIsShopCBD(false)
                }}
              >
                {customDropdown?.title}
              </Text>
            </Flex>
            {isShopCBD ? (
              <Flex
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  overflowY: 'auto',
                }}
              >
                {dropdown?.linksCollection?.items?.map((link, index) => {
                  const id = getHeaderLinkId({ url: link.url, text: link.text })

                  return (
                    <DropdownItem
                      {...link}
                      isOpen={isOpen}
                      key={`dropdown-link-${id}`}
                      index={index}
                      totalLength={dropdown?.linksCollection?.items.length}
                      onClick={onClickLink}
                    />
                  )
                })}
              </Flex>
            ) : (
              <Flex
                sx={{
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  overflow: 'auto',
                }}
              >
                {customDropdown?.linksCollection?.items?.map((link, index) => {
                  const id = getHeaderLinkId({ url: link.url, text: link.text })

                  return (
                    <DropdownItem
                      {...link}
                      isOpen={isOpen}
                      key={`dropdown-link-${id}`}
                      index={index}
                      totalLength={dropdown?.linksCollection?.items.length}
                      onClick={onClickLink}
                    />
                  )
                })}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Box>
    )
  }

  // // console.log('[dropdown]', { isOpen, links })

  // const headerHeight = useCurrentHeaderHeight()

  let display
  if (isOpen) {
    display = ['block']
    if (mobileHidden) {
      display = ['none', 'block']
    }
  }

  return (
    <Box
      sx={{
        visibility: isOpen ? 'visible' : 'hidden',
        opacity: isOpen ? 1 : 0,
        display,
        position: [null, 'absolute'],
        top: [null, HeaderHeight[1]],
        width: [null, '100%'],
        left: [null, 0],
        right: [null, 0],
        height: [null, `calc(100vh - ${HeaderHeight[1]}px)`],
        background: [null, "rgba(0, 0, 0, 0.5)"],
      }}
      onClick={close}
      className="dropdown"
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          pointerEvents: 'none',
        }}
      />
      <Box
        sx={{
          backgroundColor: [null, 'muted'],
          borderTopStyle: [null, 'solid'],
          borderWidth: [null, 1],
          borderColor: [null, 'border'],
          boxShadow: [null, 'rgba(0, 0, 0, 0.09) 0px 16px 24px 0px'],
        }}
        // onBlur={() => {
        //   if (breakpointIndex > 0) {
        //     setDropdownId(null)
        //   }
        // }}
        // onMouseLeave={() => {
        //   if (breakpointIndex > 0) {
        //     setDropdownId(null)
        //   }
        // }}
      >
        <Container>
          <SlideDown onClick={(e) => e.stopPropagation()}>
            {renderLinks()}
          </SlideDown>
        </Container>
      </Box>
    </Box>
  )
}

export default memo(Dropdown)
